<template>
  <a-card class="card-area">
    <div class="operator">
      <a-button @click="addPropagate" type="primary" ghost>新增</a-button>
      <a-button @click="batchDelete" >删除</a-button>
    </div>
    <a-table 
      bordered 
      :columns="columns" 
      :rowKey="(record) => record.id"
      :data-source="dataSource" 
      :rowSelection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange,}"
      :loading="loading"
      :pagination="pagination" 
      @change="handleTableChange" 
    >
      <a-tag :color="text=='1'?'#87d068':'red'" slot="status" slot-scope="text">{{ text=='1'?"启用":"禁用" }}</a-tag>
      <template slot="operation" slot-scope="text, record">
        <a-icon type="edit" theme="twoTone" twoToneColor="#4a9ff5" @click="editPropagate(record)" title="修改"></a-icon>
      </template>
    </a-table>
    <propagate-edit ref="propagateEdit" @close="handlePropagateEditClose" @success="handlePropagateEditSuccess" :propagateEditVisiable="propagateEditVisiable"></propagate-edit>
    <propagate-add ref="propagateAdd" @close="handlePropagateAddClose" @success="handleproPagateAddSuccess" :propagateAddVisiable="propagateAddVisiable"></propagate-add>
  </a-card>
</template>

<script>
import PropagateAdd from "./PropagateAdd"
import PropagateEdit from "./PropagateEdit"

const columns = [
  // {
  //   title: "序号",
  //   dataIndex: "key",
  //   width: 100
  // },
  {
    title: "标题",
    dataIndex: "title",
    width: 300
    // ellipsis: true
  },
  // {
  //   title: "宣传图",
  //   dataIndex: "image",
  //   ellipsis: true
  // },
  // {
  //   title: "宣传片",
  //   dataIndex: "video",
  //   ellipsis: true
  // },
  {
    title: "描述",
    dataIndex: "desc",
    // ellipsis: true
  },
  {
    title: "排序",
    dataIndex: "sort",
    // ellipsis: true,
    width: 100
  },
  {
    title: "状态",
    dataIndex: "status",
    scopedSlots: { customRender: 'status' },
    // ellipsis: true,
    width: 100
  },
  {
    title: "操作",
    dataIndex: "operation",
    scopedSlots: { customRender: 'operation' },
    fixed: "right",
    width: 80
  }
];

export default {
  components: {
    PropagateAdd,
    PropagateEdit
  },
  data() {
    return {
      dataSource: [],
      selectedRowKeys: [],
      columns,
      paginationInfo: null,
      pagination: {
        pageSizeOptions: ['10', '20', '30', '40', '100'],
        defaultCurrent: 1,
        defaultPageSize: 10,
        showQuickJumper: true,
        showSizeChanger: true,
        showTotal: (total, range) => `显示 ${range[0]} ~ ${range[1]} 条记录，共 ${total} 条记录`
      },
      loading: false,
      propagateAddVisiable: false,
      propagateEditVisiable: false
    };
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch(params = {}) {
      this.loading = true;
      if(this.paginationInfo) {
        // 如果分页信息不为空，则设置表格当前第几页，每页条数，并设置查询分页参数
        this.$refs.TableInfo.pagination.current = this.paginationInfo.current
        this.$refs.TableInfo.pagination.pageSize = this.paginationInfo.pageSize
        params.pageSize = this.paginationInfo.pageSize
        params.pageNum = this.paginationInfo.current
      } else {
        // 如果分页信息为空，则设置为默认值
        params.pageSize = this.pagination.defaultPageSize
        params.pageNum = this.pagination.defaultCurrent
      }
      this.$get("company_propagate", {...params}).then((r) => {
        // console.log("获取到的公司宣传列表", r.data);
        const pagination = { ...this.pagination };
        pagination.total = r.data.total;
        this.dataSource = r.data.rows || [];
        this.pagination = pagination;
        this.loading = false;
      });
    },
    handleTableChange (pagination, filters, sorter) {
      this.paginationInfo = pagination;
      this.fetch();
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    addPropagate() {
      this.propagateAddVisiable = true;
    },
    handleproPagateAddSuccess() {
      this.propagateAddVisiable = false;
      this.$message.success("添加企业宣传成功！");
      this.fetch();
    },
    handlePropagateAddClose() {
      this.propagateAddVisiable = false;
    },
    editPropagate(record) {
      this.propagateEditVisiable = true;
      this.$refs.propagateEdit.setFormField(record);
    },
    handlePropagateEditSuccess() {
      this.propagateEditVisiable = false;
      this.$message.success("修改企业宣传成功！");
      this.fetch();
    },
    handlePropagateEditClose() {
      this.propagateEditVisiable = false;
    },
    batchDelete() {
      if(!this.selectedRowKeys.length) {
        this.$message.warning("请选择需要删除的记录");
      } else {
        let that = this;
        that.$confirm({
          title: "确定删除所选中的记录？",
          content: "当您点击确定按钮后，这些记录将会被彻底删除",
          centered: true,
          onOk () {
            let ids = that.selectedRowKeys.join();
            that.$delete(`company_propagate/${ids}`)
              .then(() => {
                that.$message.success("删除成功！");
                that.selectedRowKeys = [];
                that.fetch();
              })
              .catch(e => console.log("企业宣传删除失败！==> ", e));
          },
          onCancel () {
            that.selectedRowKeys = [];
          }
        })
      }
    }
  }
};
</script>


<style lang="less" scoped>
@import "../../../utils/Common.less";
</style>
